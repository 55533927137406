import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { DownloadIcon, AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";

const forms = [
  {
    title: "General Consent",
    description: "A general consent form",
    filename: "/forms/gen_consent.pdf",
  },
  {
    title: "New Patient",
    description: "Please download this form if you are a new patient",
    filename: "/forms/new_patient.pdf",
  },
  {
    title: "Health Information Release",
    description: "Help us improve our services",
    filename: "/forms/health_info_release.pdf",
  },
  {
    title: "Controlled Substance Contract",
    description: "Download the controlled substance contract form",
    filename: "/forms/controlled_substances.pdf",
  },
  {
    title: "No Show Form",
    description: "Please download this form to understand our no-show policy",
    filename: "/forms/no_show.pdf",
  },
  {
    title: "Patient Record of Disclosures",
    description: "Download the patient record of disclosures form",
    filename: "/forms/patient_record_of_disclosures.pdf",
  },
];

const OnlineForms = () => {
  const [error, setError] = useState(null);

  const handleDownload = async (filename) => {
    try {
      const response = await fetch(filename);
      if (!response.ok) {
        throw new Error("File not found");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError(
        `Unable to download ${filename
          .split("/")
          .pop()}. Please try again later or contact support.`
      );
    }
  };

  return (
    <div className="container mx-auto p-4 py-8">
      <h1 className="text-4xl font-bold text-center mb-8">
        Downloadable Forms
      </h1>

      {error && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {forms.map((form, index) => (
          <Card key={index} className="shadow-lg border flex flex-col">
            <CardHeader>
              <CardTitle>{form.title}</CardTitle>
              <CardDescription>{form.description}</CardDescription>
            </CardHeader>
            <CardContent className="flex-grow">
              <p className="text-sm text-gray-500">
                Click the button below to download the PDF form.
              </p>
            </CardContent>
            <CardFooter className="mt-auto">
              <Button
                variant="outline"
                className="w-full border-black text-black hover:bg-black hover:text-white transition-colors duration-300"
                onClick={() => handleDownload(form.filename)}
              >
                <div className="flex justify-center items-center">
                  <DownloadIcon className="mr-2 h-4 w-4" />
                  Download PDF
                </div>
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default OnlineForms;
