"use client";

import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { ChevronRight } from "lucide-react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Marquee from "../../node_modules/react-fast-marquee/dist/index";
import bkgrd from "panoramic.jpg";
import { CircleUser } from "lucide-react";
import { Star } from "lucide-react";
import { Hospital, Phone } from "lucide-react";
import { MoveRight } from "lucide-react";
const testimonials = [
  {
    id: 1,
    name: "J S",
    image: "/placeholder.svg?height=100&width=100",
    text: "I absolutely love Dr. Motorwala and her staff. She is very attentive to your concerns and health care needs. Waiting time has been no more than 5 minutes of arriving for my appointments and I've never felt rushed during my visits.",
  },
  {
    id: 2,
    name: "Dearborn 313",
    image: "/placeholder.svg?height=100&width=100",
    text: "Wonderful Dr. that actually take the time to listen,treat and advise patients. Based on my experience with her she is a very down to earth beautiful women. She also treats her pt with the best of the best in terms of treatment, Rx, technology and attitude. The office and environment can not get any better. You should come see the place for yourself. I recommend Dr. Motorwala to every pt possible. One doctor and one way as far as health goes!! You get what you came for and more!",
  },
  {
    id: 3,
    name: "Love Yourself",
    image: "/placeholder.svg?height=100&width=100",
    text: "🎉Dr. Motorwala is a wonderful Doctor, she listens, she has patience, and explains everything where you can understand, I absolutely love her, she been my doctor now for 8yrs🥰",
  },
  {
    id: 4,
    name: "Zedia Lane",
    image: "/placeholder.svg?height=100&width=100",
    text: "I have been blessed to work with this wonderful doctor. Dr. Motorwala shows sincere concern for the care of her patients and staff. I highly recommend Dr. Motorwala to anyone who really is looking for a good doctor, you search ends here.",
  },
  {
    id: 5,
    name: "Ping Zhou",
    image: "/placeholder.svg?height=100&width=100",
    text: "Wonderful doctor who takes her time, listens, and explains everything in detail.  Seems genuinely caring and her staff is also great and personable.",
  },
  {
    id: 6,
    name: "Kirshbia",
    image: "/placeholder.svg?height=100&width=100",
    text: "Went in for a drug test. A singular lady was handling everything. She was prompt, attentive, and she explained the entire process. A good place for pre-employment screenings.",
  },
  {
    id: 7,
    name: "David Taylor",
    image: "/placeholder.svg?height=100&width=100",
    text: "The clinic exceeded my expectations.",
  },
  {
    id: 8,
    name: "Bruce Bonkowski",
    image: "/placeholder.svg?height=100&width=100",
    text: "There a caring center and listen to you not blow you off  handle most insurance even Molina healthcare",
  },
];
const handleTakeMeThereClick = () => {
  const address = "2116 S Wayne Rd, Westland, MI 48186";
  const encodedAddress = encodeURIComponent(address);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Open appropriate maps based on device type
  if (isMobile) {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`,
      "_blank"
    );
  } else {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`,
      "_blank"
    );
  }
};

const handleCallUsClick = () => {
  window.open("tel:+17346298971");
};
export default function HomePage() {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000); // Change testimonial every 5 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Hero Section */}
      <section
        className="relative h-[60vh] w-full"
        style={{
          backgroundImage: `url(${bkgrd})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0  flex items-end justify-center pb-8">
          <div className="flex flex-row space-x-4">
            {/* Take Me There Button */}
            <Button
              size="lg"
              className="flex items-center text-lg bg-white rounded-full px-6 p-3"
              onClick={handleTakeMeThereClick}
            >
              Take Me There
              <Hospital className="ml-2 h-5 w-5" />
            </Button>

            {/* Call Us Button */}
            <Button
              size="lg"
              className="flex items-center text-lg bg-white rounded-full px-6 p-3"
              onClick={handleCallUsClick}
            >
              Call Us
              <Phone className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </div>
      </section>

      {/* About Clinic Section */}
      <section className="py-16 px-4 max-w-4xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">
          Apex Medical of Westland Michigan
        </h2>
        <p className="text-2xl text-muted-foreground">
          Welcome to Apex Medical, your trusted clinic in Westland, MI,
          specializing in <b>Internal Medicine.</b> Our warm and dedicated staff
          is committed to delivering top-tier care to ensure your health and
          well-being. At Apex Medical, we offer a range of essential services
          including vaccinations, flu shots, annual health exams, school and
          sports physicals, gynecological exams, blood work, diabetic care, and
          much more—all designed to meet your individual health needs.
        </p>
        <br />
        <p className="text-2xl text-muted-foreground">
          <b>Same-day appointments</b> are available for sick visits. Call us
          today at 734-629-8971 to schedule your first appointment.
        </p>
        <br />
        <p className="text-2xl text-muted-foreground">
          We look forward to supporting your health journey for many years to
          come!
        </p>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 bg-muted bg-[#F4F4F9]">
        <div className="w-full">
          <h2 className="text-3xl font-bold mb-10 text-center">
            What Our Patients Say
          </h2>
          <Marquee gradient={false} speed={50} pauseOnHover>
            {testimonials.map((testimonial) => (
              <div key={testimonial.id} className="mx-3">
                <Card className="p-6 h- flex flex-col justify-between">
                  {" "}
                  {/* Consistent height */}
                  {/* Star Rating */}
                  <div className="flex justify-center">
                    {[...Array(5)].map((_, index) => (
                      <Star key={index} className="w-5 h-5 text-yellow-500" />
                    ))}
                  </div>
                  {/* User Icon and Testimonial */}
                  <div className="flex flex-col md:flex-row items-center md:items-start text-center md:text-left">
                    <div className="w-24 h-24 rounded-full mb-4 md:mb-0 md:mr-6">
                      <CircleUser className="w-24 h-24" />
                    </div>
                    <div className="max-w-md">
                      <p className="text-md mb-2 truncate overflow-hidden text-ellipsis max-h-16">
                        {testimonial.text}
                      </p>
                      <p className="font-semibold">{testimonial.name}</p>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </Marquee>
        </div>
        <h2 className="text-3xl font-bold mb-5 text-center mt-10 flex items-center justify-center">
          <a
            href="https://www.google.com/search?q=apex+medical&rlz=1C5CHFA_enUS940US940&oq=apex+medical&gs_lcrp=EgZjaHJvbWUqDQgAEAAY4wIYsQMYgAQyDQgAEAAY4wIYsQMYgAQyEwgBEC4YrwEYxwEYsQMYgAQYjgUyBwgCEAAYgAQyBwgDEAAYgAQyBwgEEAAYgAQyBggFEEUYPTIGCAYQRRg9MgYIBxBFGDzSAQgyMDUwajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x883b4dcfa36e1361:0x19c0afc559ef5ade,3,,,,"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center group"
          >
            <span className="mr-2 group-hover:text-blue-500 transition-colors duration-300">
              Liked your visit? Write A Review
            </span>
            <span className="font-bold transform group-hover:translate-x-2 transition-transform duration-300">
              <MoveRight />
            </span>
          </a>
        </h2>
      </section>
    </div>
  );
}
