import doctor from "./drshahina.jpg";

const OurPractice = () => {
  return (
    <div>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-center mb-8">Our Practice</h1>

        <div className="grid md:grid-cols-2 gap-8 mb-12 text-start">
          <div className="space-y-4">
            <h2 className="text-2xl font-semibold">Dr. Shahina Motorwala</h2>
            <p className="text-gray-600">
              Shahina Motorwala MD, is a board-certified Internal Medicine
              physician practicing since 2009. Dr. Motorwala earned her medical
              degree from K.J. Somaiya Medical School in India and completed her
              Internal Medicine residency at St. Joseph Mercy Hospital in Ann
              Arbor, Michigan.
            </p>
            <p className="text-gray-600">
              Dr. Motorwala has clinical interests in preventive medicine,
              women’s wellness and management of chronic conditions. She offers
              outstanding care both in-patient (hospital) and out-patient
              (office) settings. Dr. Motorwala enjoys serving patients with
              diverse background and is fluent in Hindi, Urdu, Gujarati, and
              Marathi.
            </p>

            <p className="text-gray-600">
              Dr. Motorwala is married with 3 children.
            </p>
            <ul className="text-gray-600 space-y-2">
              <li>
                <strong>Medical School:</strong> K.J Somaiya Medical College,
                India
              </li>
              <li>
                <strong>Residency:</strong> St. Joseph Mercy Health System, Ann
                Arbor, MI
              </li>
              <li>
                <strong>Practicing Since:</strong> 2009
              </li>
              <li>
                <strong>Board Certified:</strong> Internal Medicine
              </li>
              <li>
                <strong>Accepting New Patients:</strong> Yes
              </li>
            </ul>
          </div>
          <div className="flex justify-center items-center">
            <img
              src={doctor}
              alt="Dr. Shahina Motorwala"
              width={300}
              height={400}
              className="rounded-lg shadow-lg"
            />
          </div>
          <p className="text-blue-800 italic font-bold">
            “ I believe in an open, honest, and compassionate physician-patient
            relationship. I like to treat the patient as a whole and not just
            the disease. I understand and incorporate various cultural
            backgrounds in the management of the disease process. ”
          </p>
        </div>

        <div className="mt-12">
          <h2 className="text-2xl font-semibold mb-4">Our Services</h2>
          <ul className="grid md:grid-cols-2 gap-4 list-disc list-inside">
            <li>Geriatric & Adult Care</li>
            <li>Women’s Care</li>
            <li>Annual Health Exams</li>
            <li>In-house Testing (EKG, Spirometry, Holtor Monitor)</li>
            <li>Management of Chronic Conditions</li>
            <li>School/Sports Physicals</li>
            <li>Referrals to Specialists</li>
            <li>Vaccinations (Flu, Tetanus, Pneumonia)</li>
            <li>Urine Analysis</li>
            <li>Blood Work</li>
            <li>Flu Shots</li>
            <li>Tetanus Vaccine</li>
            <li>Work-Related Injuries</li>
          </ul>
        </div>

        <div className="mt-12">
          <p className="text-gray-600">
            APEX Medical is committed to providing personalized, high-quality
            health care to all of our valued patients. Our expert, caring staff
            strives to provide medical care, treatment, education, and health
            solutions through a team alliance between patient, physician, office
            staff, health plans, and specialty services. We promote patient
            empowerment through one-on-one support, innovative leading-edge
            technology, and progressive medical services.
          </p>
        </div>
      </div>
    </div>
  );
};
export default OurPractice;
