import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation
import { Menu, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import icon from "./apexmedicon.png";
import logo from "./apexlogo.png";

function CombinedNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get the current location
  const activePath = location.pathname; // Get the pathname from the location object

  const navItems = [
    { href: "/", label: "Home" },
    { href: "/our-practice", label: "Our Practice" },
    { href: "/online-forms", label: "Online Forms" },
    { href: "/contact-us", label: "Contact Us" },
  ];

  return (
    <nav className="bg-background border-b">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <img src={logo} alt="Apex Medical Icon" className="h-12 " />
            <Link to="/" className="flex-shrink-0">
              <span className="text-2xl font-bold text-primary">
                Apex Medical Center
              </span>
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex ml-10 space-x-8">
            {navItems.map((item) => {
              const isActive = activePath === item.href;
              return (
                <Link
                  key={item.href}
                  to={item.href}
                  className={`text-lg px-3 py-2 rounded-full transition-colors ${
                    isActive ? "bg-blue-200" : "hover:bg-blue-100"
                  }`}
                >
                  {item.label}
                </Link>
              );
            })}
          </div>

          {/* Mobile Hamburger Button */}
          <div className="flex md:hidden">
            <Button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </Button>
          </div>
        </div>
      </div>

      {/* Slide-in Mobile Menu */}
      <div
        className={`fixed inset-y-0 left-0 w-full bg-white shadow-lg transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <div className="p-4 flex items-center justify-between">
          <span className="text-2xl font-bold text-primary">Menu</span>
          <Button onClick={() => setIsOpen(false)}>
            <X className="w-6 h-6" />
          </Button>
        </div>
        <div className="space-y-1 px-4 pt-2">
          {navItems.map((item) => (
            <Link
              key={item.href}
              to={item.href}
              className="block text-muted-foreground hover:text-primary px-3 py-2 rounded-md text-base font-medium"
              onClick={() => setIsOpen(false)} // Close menu on link click
            >
              {item.label}
            </Link>
          ))}
        </div>
      </div>

      {/* Overlay for when menu is open */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </nav>
  );
}

export default CombinedNavbar;
