import gch from "./gch.png";
import hfh from "./hfh.png";
import trinity from "./trinity.jpeg";
import corewell from "./corewell.jpeg";

export default function Footer() {
  const partners = [
    { name: "Partner 1", logo: gch },
    { name: "Partner 2", logo: hfh },
    { name: "Partner 3", logo: trinity },
    { name: "Partner 4", logo: corewell },
  ];

  const links = [
    { name: "Home", href: "/" },
    { name: "Our Practice", href: "/our-practice" },
    { name: "Online Forms", href: "/online-forms" },
    { name: "Contact", href: "/contact-us" },
  ];

  return (
    <footer>
      <div className="bg-[#042856]">
        <div className="container mx-auto px-4 py-8 ">
          {/* Partners Section */}
          <section className="mb-8 p-10">
            <div className="flex justify-center items-center gap-4 flex-wrap">
              {partners.map((partner) => (
                <div
                  key={partner.name}
                  className="w-36 h-24 bg-white p-2 rounded-lg shadow-md flex items-center justify-center m-2"
                >
                  <img
                    src={partner.logo}
                    alt={`${partner.name} logo`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
      <div className="bg-[#ddeeff] p-10">
        <nav className="mb-8">
          <ul className="flex flex-wrap justify-center space-x-4 sm:space-x-8">
            {links.map((link) => (
              <li key={link.name}>
                <a
                  href={link.href}
                  className="text-muted-foreground hover:text-primary"
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>

        {/* Copyright Section */}
        <div className="text-center text-sm text-muted-foreground">
          © {new Date().getFullYear()} Apex Medical Center. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
