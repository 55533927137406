import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./navbar";
import HomePage from "components/Home";
import OurPractice from "components/OurPractice";
import OnlineForms from "components/OnlineFarms";
import Contact from "components/Contact";
import Footer from "components/Footer";
import CombinedNavbar from "./navbar";

function App() {
  return (
    <Router>
      <CombinedNavbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/our-practice" element={<OurPractice />} />
        <Route path="/online-forms" element={<OnlineForms />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
